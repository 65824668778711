$(function () {

    /* ==== Reduce size of header on scroll ==== */
    $(window).on("load scroll", function () {
        var scroll = $(window).scrollTop();

        if (scroll >= 100) {
            $(".header--small").addClass('header--small--show');
        } else {
            $(".header--small").removeClass('header--small--show');
        }
    });


    /* === Navigation === */
    var navItem = $('.nav__item--parent');

    navItem.each(function () {
        if ($(this).find('.nav__dropdown').length > 0) {
            $(this).addClass('nav__item--has-dropdown');
        }
    });

    $("#topHamburger").click(function () {
        var dropdown = $("#extraDropdown"); 
        if(dropdown.hasClass("active"))
        {
            dropdown.removeClass("active");
        }
        else {
            dropdown.addClass("active");
        }
        
    });

    /* === Mobile nav === */
    $('.menu-trigger').click(function () {
        $(this).toggleClass('menu-trigger--active');
        $('nav').toggleClass('nav--open'); 
        $('body').toggleClass('no-scroll');
    });



    /* === cookie notice === */
    if (Cookies.get('ealtCookie')) {
        $('.cookie-notice').hide();
    } else {
        $('.cookie-notice').css('display', 'block');
        $('.cookie-notice__close').click(function () {
            Cookies.set('ealtCookie', 'accepted', {
                expires: 365
            });
            $(this).parent().parent().hide();
        });
    }

    /* === ie support === */
    objectFitImages();


    /* Remove underline from button link */
    if ($('.button--content').parent('a').length) {
        $('.button--content').parent('a').toggleClass('no-underline');
    };


    /* === Add aria labels to owl carousel buttons === */
    $('.owl-carousel').each(function() {
        //Find each set of dots in this carousel
        $(this).find('.owl-dot').each(function(index) {
            //Add one to index so it starts from 1
            $(this).attr('aria-label', index + 1);
        });
    });



    /* === Modal === */
    // $('.modal-trigger').click(function () {
    //     $('.modal-overlay').toggleClass('active');
    // });

    // $('.modal-overlay').on('click', '.modal-box .modal-close', function (event) {
    //     $('body').removeClass('no-scroll');
    //     event.preventDefault();
    //     $('.modal-overlay').removeClass('active');
    //     $('.modal-box img').removeClass('fade-in');
    //     $('.modal-box img').attr('src', '');
    // });

    // $('body').on('click', '.modal-overlay', function (event) {
    //     if (!$(event.target).is('.modal-box, .modal-close')) {
    //         $('body').removeClass('no-scroll');
    //         event.preventDefault();
    //         $('.modal-overlay').removeClass('active');
    //         $('.modal-box img').removeClass('fade-in');
    //         $('.modal-box img').attr('src', '');
    //     }
    // })





});

Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}; 

$(window).on("load", function () {
	// setTimeout(function () {
	// 	$("body").removeClass("loading");
	// }, 1000)

	if (/Edge/.test(navigator.userAgent)) {
		$('body').addClass('ie ie--edge');
	}

	if (navigator.userAgent.indexOf('MSIE') !== -1 ||
		navigator.appVersion.indexOf('Trident/') > -1) {
		$('body').addClass('ie ie--11');
	}
    
    if (navigator.appVersion.indexOf("MSIE 10") !== -1)
    {
        $('body').addClass('ie ie--10');
    }
});



